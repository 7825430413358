/**
 * When user comes to webapp via urls which contains tokens
 * We need to disable tracking urls as this will leak token information to external services
 */

const SenstivePaths = ['/new-password'];

let startedOnSensitivePath = false;

export function storeStartUrlInfo() {
  let pathname = window.location.pathname;

  startedOnSensitivePath = SenstivePaths.some((item) => pathname.startsWith(item));
}

export function isUrlTrackingDisabled() {
  return startedOnSensitivePath;
}
