import React, { lazy, StrictMode, Suspense, useEffect } from 'react';
import { acceptConsentWithoutBanner } from 'views/consentBanner/consent-utils';
import GlobalSnackBar from 'views/global/SnackBar/GlobalSnackBar';
import { setDefaultOptions } from 'date-fns';
import { TooltipProvider } from '@radix-ui/react-tooltip';
import { createRoot, Root } from 'react-dom/client';

export type AppContainerType =
  | 'container'
  | 'container-login'
  | 'container-external'
  | 'container-shared'
  | 'module-container';

const UnAuthorizedContainerAppLazy = lazy(
  () => import(/* webpackChunkName : "login-container" */ 'views/UnAuthorizedContainerApp')
);

const AuthorizedContainerAppLazy = lazy(
  () => import(/* webpackChunkName : "main-container" */ 'views/AuthorizedContainerApp')
);

const ModuleContainerAppLazy = lazy(
  () => import(/* webpackChunkName : "module-container" */ 'views/ModuleContainerApp')
);

let root: Root | null = null;

export function renderRootApp(container: AppContainerType, to = '') {
  const appRoot = document.getElementById('fileee-app');

  if (container === 'container-external' || container === 'module-container') {
    acceptConsentWithoutBanner();
  }

  if (!root) {
    root = createRoot(appRoot);
  }

  root.render(
    <StrictMode>
      <Suspense fallback={null}>
        <TooltipProvider>
          <RootApp key={container} container={container} to={to} />
        </TooltipProvider>
        <GlobalSnackBar />
      </Suspense>
    </StrictMode>
  );
}

function RootApp({ container, to = '' }: { container: AppContainerType; to?: string }) {
  useEffect(() => {
    setDefaultOptions({ weekStartsOn: 1 });
  }, []);

  switch (container) {
    case 'container': {
      return <AuthorizedContainerAppLazy navigateTo={to} />;
    }
    case 'container-login': {
      return <UnAuthorizedContainerAppLazy />;
    }
    case 'module-container': {
      return <ModuleContainerAppLazy navigateTo={to} />;
    }
    default: {
      return <UnAuthorizedContainerAppLazy />;
    }
  }
}
