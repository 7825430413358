import { FileeePrimaryColor } from 'jsStyles/theme';
export const DEFAULT_BACKGROUND = FileeePrimaryColor;
export const DEFAULT_TEXT = '#FFFFFF';

export const COOPERATION_NAME_KEY = 'com.fileee.i18n.partner.cooperation.type.name';

export const EXTERNAL_REF_PARAM = 'ref';
export const FRONTPAGE_REF = 'frontpage_premium';

export const BUSINESS_BUNDLE = 'business_bundle';

export const ENTERPRISE_GROUP = 'enterprise';

export const SELECTION_SIGNAL = 'selection-signal';

export const BRANDING_COOKIE = 'fileee_branding';

export const TRACKING_COOKIE = 'fileee-tracking';

export const DOMAIN_COOKIE = 'fileee-domain';

export const FIELD_TEMPLATE_SELECTED = 'field-template-selected';

export const APP_HINT_COOKIE = 'fileee-force-mobile-view';

export const DATE_FORMATS = {
  DE: 'DD.MM.YYYY',
  EN: 'MM/DD/YYYY'
};

// https://my.fileee.com/account?promotion_fileee_premium_3_months_trial=true
export const PROMOTION_PARAMS = {
  BUSINESS_BUNDLE: 'business_bundle',
  MAC_AND_I: 'promotion_mac_and_i',
  VGH_PREMIUM_TRIAL: 'promotion_vgh_premium_3_months_trial',
  FILEEE_PREMIUM_TRIAL: 'promotion_fileee_premium_3_months_trial'
};
