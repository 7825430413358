import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import { getPerformance, trace, PerformanceTrace, FirebasePerformance } from 'firebase/performance';
import { isUrlTrackingDisabled } from 'startup/start-url-actions';

import { getGlobalSettings } from './global-settings';
import { isTestEnv } from './utils';

let app: FirebaseApp;
let perf: FirebasePerformance;

export function setupProfiler() {
  const firebaseConfig: FirebaseOptions = {
    apiKey: 'AIzaSyC3_Ht_QqDJEZf1oiqJPolYwlaRKmp5t9E',
    authDomain: 'api-project-774724740809.firebaseapp.com',
    databaseURL: 'https://api-project-774724740809.firebaseio.com',
    projectId: 'api-project-774724740809',
    storageBucket: 'api-project-774724740809.appspot.com',
    messagingSenderId: '774724740809',
    appId: isTestEnv()
      ? '1:774724740809:web:ad1a46eb8d937e46'
      : '1:774724740809:web:187c0d7cec06c70a224f18'
  };

  app = initializeApp(firebaseConfig);

  app.automaticDataCollectionEnabled = !isUrlTrackingDisabled();

  perf = getPerformance(app);
  perf.dataCollectionEnabled = !isUrlTrackingDisabled();
  perf.instrumentationEnabled = !isUrlTrackingDisabled();
}

const localProfilingEnabled = getGlobalSettings().enableLocalProfiling || __DEV__;
export function startLocalProfiling(name = '') {
  if (localProfilingEnabled) {
    console.time(`[PERF]- ${name}`);
  }
}

export function stopLocalProfiling(name = '') {
  if (localProfilingEnabled) {
    console.timeEnd(`[PERF]- ${name}`);
  }
}

type ContainerNameEnum = 'Login' | 'External' | 'App';

let alreadyTrackedContainerOnce = false;

export function profileContainerMounted(containerName: ContainerNameEnum) {
  if (alreadyTrackedContainerOnce) return;

  performance && performance.measure(`${containerName}ContainerMounted`);
}

function getStartLabel(label) {
  return `${label} - Start`;
}

function getEndLabel(label) {
  return `${label} - End`;
}

export function startProfiler(label) {
  performance && performance.mark(getStartLabel(label));
}

export function endProfiler(label) {
  if (!performance) return;
  performance.mark(getEndLabel(label));
  performance.measure(label, getStartLabel(label), getEndLabel(label));
}

export function getPerfTrace(label: string): PerformanceTrace {
  return trace(perf, label);
}
