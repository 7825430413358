import { getConfiguredRollbar } from './setup-rollbar';

export class FileeeLogAppender {
  debug(logger, message, ...rest) {
    console.debug(`[JS] DEBUG ` + this.getTime() + ` [${logger.id}] ${message}`, ...rest);
  }
  info(logger, message, ...rest) {
    console.info(`[JS] INFO ` + this.getTime() + ` [${logger.id}] ${message}`, ...rest);
  }
  warn(logger, message, ...rest) {
    console.warn(`[JS] WARN ` + this.getTime() + ` [${logger.id}] ${message}`, ...rest);
    getConfiguredRollbar().warn(`[JS] ${this.getTime()} [${logger.id}] ${message}`, ...rest);
  }
  error(logger, message, ...rest) {
    console.error(`[JS] ERROR ` + this.getTime() + ` [${logger.id}] ${message}`, ...rest);
    getConfiguredRollbar().error(`[JS] ${this.getTime()} [${logger.id}] ${message}`, ...rest);
  }
  critical(logger, message, ...rest) {
    console.error(`[JS] ERROR ` + this.getTime() + ` [${logger.id}] ${message}`, ...rest);
    getConfiguredRollbar().critical(`[JS] ${this.getTime()} [${logger.id}] ${message}`, ...rest);
  }

  getError(message, rest) {
    if (message instanceof Error) {
      return message;
    } else {
      let filteredArray = rest.filter((x) => x instanceof Error);
      return filteredArray.length > 0 ? filteredArray[0] : undefined;
    }
  }

  getTime() {
    var currentdate = new Date();
    var minutes = currentdate.getMinutes();
    var seconds = currentdate.getSeconds();
    return (
      currentdate.getHours() +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes) +
      ':' +
      (seconds < 10 ? '0' + seconds : seconds)
    );
  }
}
