//@ts-check
export * from './colors';
export * from './linkify-util';

/**
 * @export
 * @returns {boolean}
 */
export function isMobileOrTablet() {
  var check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

/**
 * https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
 */
export function isMobileIOS() {
  const iOSPlatforms = ['iPhone Simulator', 'iPod Simulator', 'iPhone', 'iPod'];
  if (!navigator.platform) return false;
  return iOSPlatforms.includes(navigator.platform);
}

/**
 *
 *
 * @export
 * @param {any} value
 * @returns {boolean}
 */
export function isInt(value) {
  if (isNaN(value)) {
    return false;
  }
  var x = parseFloat(value);
  return (x | 0) === x;
}

/**
 * Removes all characters other than numbers and seperators
 * @param {string} value
 * @returns {string}
 */
export function parseStringToNumber(value = '') {
  const allNumberStrings = value.match(/[0-9]*\.?,?-?[0-9]+/g);
  if (allNumberStrings) {
    return allNumberStrings.join('');
  } else {
    return '';
  }
}

export const ANDROID_STORE_LINK = 'http://android.fileee.com';
export const IOS_STORE_LINK = 'http://ios.fileee.com';

/**
 *
 *
 * @export
 * @param {string[]} [strings=[]]
 * @param {string} [initialSeperator=',']
 * @param {string} [finalSeperator='&']
 * @returns
 */
export function joinWithSeperators(strings = [], initialSeperator = ',', finalSeperator = '&') {
  if (strings.length === 0) {
    return '';
  }
  return strings.reduce((joined, current, index, array) => {
    if (array.length === 2 || index === array.length - 1) {
      return `${joined}${finalSeperator}${current}`;
    }
    return `${joined}${initialSeperator}${current}`;
  });
}

/**
 * TODO Move this to string-utils
 * return an array of strings where each string length is the given length parameter
 *
 * @export
 * @param {string} [iban=""]
 * @returns {string[]}
 */
export function getChunks(iban = '', length = 4) {
  let regex = new RegExp(`.{1,${length}}`, 'g');
  // remove all white space and then split
  return stripSpaces(iban).match(regex) || [];
}

/**
 * TODO Move this to string-utils
 * removes all spaces from the given text
 * @export
 * @param {string} [text=""]
 * @returns {string}
 */
export function stripSpaces(text = '') {
  return text.replace(/\s/g, '');
}

/**
 * TODO Move this to string-utils
 * @param {string} [title=""]
 * @returns {string}
 */
export function truncateTitle(title = '') {
  if (title.length > 20) {
    return `${title.substring(0, 20)}...`;
  } else {
    return title;
  }
}

/**
 *
 *
 * @export
 * @param {string[]} baseArray
 * @param {string[]} subArray
 * @returns {boolean}
 */
export function containsAll(baseArray, subArray) {
  if (!baseArray || !subArray || baseArray.length === 0 || subArray.length === 0) {
    return false;
  }
  return subArray.every((elem) => baseArray.indexOf(elem) !== -1);
}

/**
 *
 * @param {string} hostName
 */
function getTopPrivateDomain(hostName = '') {
  let parts = hostName.split('.').reverse();

  if (parts == null) {
    return hostName;
  }
  if (parts.length <= 2) {
    return hostName;
  }

  let lastValidStringIndex = hostName.indexOf('co.') > -1 ? 3 : 2;

  return parts.slice(0, lastValidStringIndex).reverse().join('.');
}

/**
 *
 * @param {string} hostName
 * @returns {string}
 */
export function getWildcardDomain(hostName) {
  let topPrivateDomain = getTopPrivateDomain(hostName);
  return `.${topPrivateDomain}`;
}

/**
 *
 * @param {any} objA
 * @param {any} objB
 * @returns {boolean}
 */
export function shallowEqual(objA, objB) {
  if (objA === objB) return true;

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false;
  }

  let keysA = Object.keys(objA);
  let keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) return false;

  for (let i = 0; i < keysA.length; i++) {
    if (!objB.hasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
      return false;
    }
  }

  return true;
}

/**
 *
 * @param {any} objA
 * @param {any} objB
 * @returns {boolean}
 */
export function shallowEqualWithoutFunctions(objA, objB) {
  if (objA === objB) return true;

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false;
  }

  let keysA = Object.keys(objA);
  let keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) return false;

  for (let i = 0; i < keysA.length; i++) {
    if (!objB.hasOwnProperty(keysA[i])) {
      return false;
    }
    const aProp = objA[keysA[i]];
    const bProp = objB[keysA[i]];
    if (typeof aProp === `function` && typeof bProp === `function`) {
      continue;
    }
    if (aProp !== bProp) {
      return false;
    }
  }

  return true;
}
/*
 * @returns {boolean}
 */
export function isIE11() {
  // eslint-disable-next-line
  return !window.ActiveXObject && 'ActiveXObject' in window;
}
