//@ts-check
import React from 'react';
import classNames from 'classnames';

export type ITypographyVariant =
  | 'headline1'
  | 'headline2'
  | 'headline3'
  | 'headline4'
  | 'headline5'
  | 'headline6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'caption2'
  | 'caption3'
  | 'button'
  | 'overline';

export type ITypographyProps = {
  variant: ITypographyVariant;
  tag?: any;
  className?: string;
} & React.AllHTMLAttributes<any> &
  React.LinkHTMLAttributes<any> & { to?: string };

const Typography = React.forwardRef(
  ({ tag = 'div', className, variant, ...rest }: ITypographyProps, ref) => {
    const finalClassName = classNames(className, `mdc-typography--${variant}`);

    let Comp = tag;

    return <Comp {...rest} className={finalClassName} ref={ref} />;
  }
);

Typography.displayName = 'Typography';

export default Typography;
