import { getGlobalSettings } from 'common/global-settings';
import { getCurrentLanguageFromDocument } from 'common/locale-helper';
import { FileeeI18N } from 'fileee-i18n';
import { getLogger } from 'logger';

const logger = getLogger('setup-i18n');

const lang = getCurrentLanguageFromDocument();
logger.info('locale is: ' + lang);

const i18nOptions = {
  // Callback used when loading a single resource.
  customLoad: (language, namespace, callback) => {
    if (namespace === 'nsenterprise') {
      if (language === 'de') {
        // @ts-ignore
        import(/* webpackChunkName: "nsenterprise-de" */ '../../locales/de/nsenterprise.json').then(
          (i18nJson) => {
            callback(null, i18nJson.default);
          }
        );
      } else if (language === 'ja') {
        // @ts-ignore
        import(/* webpackChunkName: "nsenterprise-ja" */ '../../locales/ja/nsenterprise.json').then(
          (i18nJson) => {
            callback(null, i18nJson.default);
          }
        );
      } else if (language === 'es') {
        // @ts-ignore
        import(/* webpackChunkName: "nsenterprise-ja" */ '../../locales/es/nsenterprise.json').then(
          (i18nJson) => {
            callback(null, i18nJson.default);
          }
        );
      } else {
        // @ts-ignore
        import(/* webpackChunkName: "nsenterprise-en" */ '../../locales/en/nsenterprise.json').then(
          (i18nJson) => {
            callback(null, i18nJson.default);
          }
        );
      }
    } else {
      if (language === 'de') {
        // @ts-ignore
        import(/* webpackChunkName: "translation-de" */ '../../locales/de/translation.json').then(
          (i18nJson) => {
            callback(null, i18nJson.default);
          }
        );
      } else if (language === 'ja') {
        // @ts-ignore
        import(/* webpackChunkName: "translation-ja" */ '../../locales/ja/translation.json').then(
          (i18nJson) => {
            callback(null, i18nJson.default);
          }
        );
      } else if (language === 'es') {
        // @ts-ignore
        import(/* webpackChunkName: "translation-ja" */ '../../locales/es/translation.json').then(
          (i18nJson) => {
            callback(null, i18nJson.default);
          }
        );
      } else {
        import(/* webpackChunkName: "translation-en" */ '../../locales/en/translation.json').then(
          (i18nJson) => {
            callback(null, i18nJson.default);
          }
        );
      }
    }
  },
  lng: lang,
  attributes: ['t', 'i18n'],
  fallbackLng: 'de',
  pluralSeparator: '_',
  debug: true
};

export async function setupI18n() {
  return FileeeI18N.setup(i18nOptions);
}
