import { getGlobalSettings } from './global-settings';

export const GERMAN = {
  value: 'de',
  serverName: 'de-DE'
};

export const ENGLISH = {
  value: 'en',
  serverName: 'en-US'
};

export const JAPANESE = {
  value: 'ja',
  serverName: 'ja-JP'
};

export const SPAIN = {
  value: 'es',
  serverName: 'es-ES'
};

const LanguageToLocaleMapping = new Map<string, string>();
const LocaleToLanguageMapping = new Map<string, string>();

LanguageToLocaleMapping.set(GERMAN.value, GERMAN.serverName);
LanguageToLocaleMapping.set(ENGLISH.value, ENGLISH.serverName);
LanguageToLocaleMapping.set(JAPANESE.value, JAPANESE.serverName);
LanguageToLocaleMapping.set(SPAIN.value, SPAIN.serverName);

LocaleToLanguageMapping.set(GERMAN.serverName, GERMAN.value);
LocaleToLanguageMapping.set(ENGLISH.serverName, ENGLISH.value);
LocaleToLanguageMapping.set(JAPANESE.serverName, JAPANESE.value);
LocaleToLanguageMapping.set(SPAIN.serverName, SPAIN.value);

/**
 *
 * @param value "en" | "de" | "ja" | "es
 * @returns "en-US" | "de-DE" | "ja-JP" | "es-ES
 */
function getLocaleFromLanguage(value = 'en') {
  let language = value.toLowerCase();
  if (LanguageToLocaleMapping.has(language)) {
    return LanguageToLocaleMapping.get(language);
  }
  return ENGLISH.serverName;
}

export function getCurrentLocaleFromDocument() {
  return document.fileeeBasicVar.language || ENGLISH.serverName;
}

export function getCurrentLanguageFromDocument() {
  return getLanguageFromLocale(getCurrentLocaleFromDocument());
}

export function getLanguageFromLocale(locale = ENGLISH.serverName) {
  if (LocaleToLanguageMapping.has(locale)) {
    return LocaleToLanguageMapping.get(locale);
  }
  return ENGLISH.value;
}

export function changeLanguageWithI18n(i18n, language = 'en') {
  let oldLocale = getGlobalSettings().language;
  let newLocale = getLocaleFromLanguage(language);

  if (oldLocale != newLocale) {
    getGlobalSettings().language = newLocale;
    document.cookie =
      'fileee_language=' + newLocale + '; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/';
    i18n.setLocale(newLocale);

    //TODO update gwt language...-> only through reload now
    //probably at least reload dyn attributes...
    // and init validation new
    // location.reload();
    location.reload();
  }
}
