import { getGlobalSettings } from 'common/global-settings';
import { isTestEnv } from 'common/utils';
import Rollbar from 'rollbar/src/browser/core';

let configuredRollbar: Rollbar = null;

export function setupRollbar() {
  configuredRollbar = Rollbar.init({
    endpoint: isTestEnv()
      ? 'https://api.workeee.de/v2/api/proxy/rollbar/api/1/item'
      : 'https://api.fileee.com/v2/api/proxy/rollbar/api/1/item',
    accessToken: __ROLLBAR_ACCESS_TOKEN__,
    captureUncaught: true,
    captureUnhandledRejections: true,
    captureIp: 'anonymize',
    captureEmail: false,
    captureUsername: false,
    enabled: shouldSendCrashReports(),
    logLevel: 'warning',
    reportLevel: 'warning',
    transform: transformTrace,
    environment: getEnvironmentForRollbar(),
    itemsPerMinute: 20,
    maxItems: 200,
    ignoredMessages: [
      // https://rollbar.com/fileee/webapp/items/61081/ Some extension is triggering this error. Not coming from fileee
      'A mutation operation was attempted on a database that did not allow mutations',
      'Transaction timed out due to inactivity.'
    ]
  });

  configuredRollbar.configure({
    payload: {
      client: {
        javascript: {
          code_version: __GIT_REVISION__,
          source_map_enabled: true
        }
      }
    }
  });

  if (getGlobalSettings().userId) {
    enableRollbarUserId(getGlobalSettings().userId);
  }
}

export function enableRollbarUserId(userId) {
  configuredRollbar.configure({
    payload: {
      person: {
        id: userId
      }
    }
  });
}

export function getConfiguredRollbar() {
  if (!configuredRollbar) {
    setupRollbar();
  }
  return configuredRollbar;
}

function getEnvironmentForRollbar() {
  const runTimeEnv = getGlobalSettings().runtimeEnv;

  if (runTimeEnv === 'live') {
    return 'live';
  } else if (runTimeEnv === 'release_candidate') {
    return 'release_candidate';
  } else if (runTimeEnv === 'staging') {
    return 'staging';
  } else if (runTimeEnv === 'test') {
    return 'test';
  } else {
    return 'unknown';
  }
}

function shouldSendCrashReports() {
  if (__DEV__) return false;
  if (BUILD_VAR_IS_EMBEDDED_APP) return false;
  return true;
}

type ErrorPayload = {
  body: {
    trace: {
      frames: TraceFrame[];
    };
  };
};

interface TraceFrame {
  colno?: number;
  filename: string;
  lineno?: number;
  method?: string;
}

export function transformTrace(payload: ErrorPayload) {
  try {
    let trace = payload.body.trace;

    if (trace && trace.frames) {
      for (let i = 0; i < trace.frames.length; i++) {
        let filename = trace.frames[i].filename;
        if (filename) {
          //   // Be sure that the minified_url when uploading includes 'dynamichost'
          trace.frames[i].filename = filename.replace(window.location.origin, DynamicHostName);
        }
      }
    }
  } catch (error) {
    console.error(`Failed to transform traces `, error);
  }
}

const DynamicHostName = 'http://dynamichost';
