import { isTestEnv, setCookieWithDomain } from 'common/utils';
import { GDPRCookieName, GDPRCookieValueEnum } from 'constants/cookie-constants';

let cookieDomain = isTestEnv() ? '.workeee.de' : '.fileee.com';

if (__DEV__) {
  cookieDomain = 'localhost';
}

export function getGdprCookieValue() {
  return window.getCookieValue(GDPRCookieName) as GDPRCookieValueEnum;
}

export function setGdprCookieValue(value: GDPRCookieValueEnum) {
  setCookieWithDomain(GDPRCookieName, value, cookieDomain);
  window.dispatchEvent(new Event('gdpr-consent-changed'));
}

export function acceptConsentWithoutBanner() {
  let currentValue = getGdprCookieValue();

  if (!currentValue) {
    setGdprCookieValue('all');
  }
}

export function autoAcceptCookies() {
  let currentValue = getGdprCookieValue();

  if (!currentValue) {
    setGdprCookieValue('all');
  }
}
