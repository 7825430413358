export const NAVIGATION_SUCCESS_CHANNEL = 'router:navigation:success';
export const HEADER_TITLE_UPDATE_CHANNEL = 'fileee:header:header-title';

export const MENU_CLICK_CHANNEL = 'fileee:menu:click';

export const ENTERPRISE_MENU_CLICK_CHANNEL = 'fileee:enterprise:menu:click';
export const ENTERPRISE_MENU_STATE_CHANNEL = 'fileee:enterprise:menu:state';

export const GENERAL_UPLOAD_FAILURE = 'fileee:upload-failure';
export const OPEN_UPLOAD = 'fileee:open-upload';
export const FILES_DROPPED = 'fileee:file-drops-for-upload';
export const SHOW_TOUR_MODEL_CONTENT = 'fileee:show-tour-modal-content';
export const OPEN_TOUR_MODAL = 'fileee:open-tour-modal';
export const OPEN_AVAILABLE_UPLOAD_ERROR = 'fileee:open-available-upload-error';
export const OPEN_MAX_FILE_SIZE_UPLOAD_ERROR = 'fileee:open-max-file-size-upload-error';

export const HEADER_CHANGE_CHANNEL = 'fileee:header:header-change';

export const SELECT_ALL_SHARED = 'fileee:shared:select-all';

export const GLOBAL_MIXPANEL = 'fileee:global-mixpanel';
