import axios from 'axios';
import { BaseApiUrl } from 'common/global-settings';

const PREFER_ERRAI_HEADER = 'X-fileee-Prefer-Errai-JSON';

export const WebappAxios = axios.create({
  baseURL: BaseApiUrl,
  withCredentials: true
});

export const ErraiBasedAxios = axios.create({
  baseURL: BaseApiUrl,
  withCredentials: true,
  headers: {
    [PREFER_ERRAI_HEADER]: true,
    'Content-Type': 'application/json'
  },
  /**
   * Don't transform response as json, as we want the actual json
   * to be unmarshalled by errai
   */
  transformResponse: (res) => {
    return res;
  }
});
