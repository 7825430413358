export function getGlobalSettings() {
  return document.fileeeBasicVar;
}

/**
 * '/api'
 */
export const BaseApiUrl = document.fileeeBasicVar.baseAPIUrl;

export function isErraiBusEnabled() {
  if (
    window.erraiBusRemoteCommunicationEnabled === undefined ||
    // @ts-ignore
    window.erraiBusRemoteCommunicationEnabled.length === 0
  ) {
    return true;
  } else {
    return window.erraiBusRemoteCommunicationEnabled;
  }
}

let inWebview = false;

export function changeToWebview() {
  inWebview = true;
}

export function isInWebView() {
  const searchParams = new URLSearchParams(window.location.search);

  return inWebview || Boolean(searchParams.get('webview'));
}
