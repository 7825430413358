//@ts-check
import * as common from '../../scripts/common';
import { getGlobalSettings } from './global-settings';
export * from '../../scripts/common';

export const FILEEE_DOMAINS = new Set([
  'https://beta.workeee.de',
  'https://my.workeee.de',
  'https://beta.fileee.com',
  'https://my.fileee.com',
  'http://localhost:9000'
]);

export const FILEEE_TEST_DOMAINS = new Set(['https://beta.workeee.de', 'https://my.workeee.de']);
const FILEEE_BETA_DOMAINS = new Set(['https://beta.fileee.com']);

export function isTestEnv() {
  const baseDomain = common.getBaseDomainUrl();

  if (FILEEE_TEST_DOMAINS.has(baseDomain)) {
    return true;
  }
  if (__DEV__) {
    return true;
  }
  return false;
}

export function isProductionEnv() {
  return (
    document.fileeeBasicVar.runtimeEnv === 'live' ||
    document.fileeeBasicVar.runtimeEnv === 'release_candidate'
  );
}

export function isLiveEnv() {
  return document.fileeeBasicVar.runtimeEnv === 'live';
}

export function isBetaEnv() {
  const baseDomain = common.getBaseDomainUrl();
  return FILEEE_BETA_DOMAINS.has(baseDomain);
}

export function getExternalLoggedInUserId() {
  return getGlobalSettings().externalUserId;
}

export function getExternalLoggedInUsername() {
  return getGlobalSettings().externalUsername || '';
}

export function clearCookie(name) {
  document.cookie = `${name}=;max-age=-1; path=/`;
}

export function addUpdateCookie({ name, value, domain, maxAge }) {
  document.cookie = `${name}=${value};domain=${domain};max-age=${maxAge}; path=/`;
}

export function clearDomainSpecificCookie(name, domain) {
  if (window.getCookieValue(name)) {
    document.cookie = `${name} = ; domain = ${domain}; max-age=-1; path=/`;
  }
}

export function clearFileeeDomainCookie(name) {
  if (window.getCookieValue(name)) {
    const domain = isTestEnv() ? '.workeee.de' : '.fileee.com';
    clearDomainSpecificCookie(name, domain);
  }
}
