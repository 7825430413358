// @ts-check
import { hasLocalStorageSupport } from './util.js';

// eslint-disable-next-line no-unused-vars
class MemoryStorage {
  constructor() {
    this.storageMap = new Map();
  }

  setItem(key, value) {
    try {
      this.storageMap.set(key, value);
    } catch (e) {
      if (__DEV__) {
        console.log('Memory storage not supported', e);
      }
    }
  }

  getItem(key) {
    try {
      return this.storageMap.get(key);
    } catch (e) {
      if (__DEV__) {
        console.log('Memory storage not supported', e);
      }
      return null;
    }
  }

  removeItem(key) {
    try {
      this.storageMap.delete(key);
    } catch (error) {
      if (__DEV__) {
        console.log('Memory storage not supported', error);
      }
    }
  }

  clear() {
    try {
      this.storageMap.clear();
    } catch (error) {
      if (__DEV__) {
        console.log('Memory storage not supported', error);
      }
    }
  }
}

class LocalStorage {
  setItem(key, value) {
    window.localStorage.setItem(key, value);
  }

  getItem(key) {
    return window.localStorage.getItem(key);
  }

  removeItem(key) {
    return window.localStorage.removeItem(key);
  }

  clear() {
    window.localStorage.clear();
  }
}

class SessionStorage {
  setItem(key, value) {
    window.sessionStorage.setItem(key, value);
  }

  getItem(key) {
    return window.sessionStorage.getItem(key);
  }

  removeItem(key) {
    return window.sessionStorage.removeItem(key);
  }

  clear() {
    window.sessionStorage.clear();
  }
}

let sessionStorage = new SessionStorage();

let fileeeStorage = sessionStorage;

if (hasLocalStorageSupport()) {
  //@ts-ignore
  fileeeStorage = new LocalStorage();
}

function getSessionStorage() {
  return sessionStorage;
}

/**
 *
 * @param {string} key
 */
function getItem(key) {
  try {
    return fileeeStorage.getItem(key);
  } catch (e) {
    if (__DEV__) {
      console.log('Local storage not supported', e);
    }
    return null;
  }
}
/**
 *
 * @param {string} key
 * @param {string} value
 */
function setItem(key, value) {
  try {
    return fileeeStorage.setItem(key, value);
  } catch (e) {
    if (__DEV__) {
      console.log('Local storage not supported', e);
    }
    return null;
  }
}

function removeItem(key) {
  try {
    fileeeStorage.removeItem(key);
  } catch (e) {
    if (__DEV__) {
      console.log('Local storage not supported', e);
    }
  }
}

function clear() {
  try {
    fileeeStorage.clear();
  } catch (error) {
    console.log('local storage not supported', error);
  }
}

export const storage = {
  setItem,
  getItem,
  removeItem,
  clear,
  getSessionStorage
};
