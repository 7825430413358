// @ts-check
import { randomId } from '../Base';

class NotificationQueue {
  listeners = [];
  notifications = [];

  /**
   *
   * @param {webapp.global.Notification} notification
   */
  addNotification(notification) {
    this.notifications = [
      {
        key: randomId('global-snackbar'),
        ...notification
      }
    ];
    this.listeners.forEach((listener) => {
      listener(this.notifications);
    });
  }

  /**
   *
   * @param {webapp.global.Notification} notification
   */
  removeNotification(notification) {
    this.notifications = this.notifications.filter((old) => old !== notification);
    this.listeners.forEach((listener) => {
      listener(this.notifications);
    });
  }

  addListener(listener) {
    this.listeners.push(listener);

    return () => {
      this.listeners = this.listeners.filter((old) => old !== listener);
    };
  }
}

export default new NotificationQueue();
