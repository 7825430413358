import { AxiosError } from 'axios';
import { setLoggedOut } from 'common/login-logout-utils';
import { isLoggedIn, setCookieWithDomain } from 'common/utils';
import { getLogger } from 'logger';
import { DOMAIN_COOKIE } from 'views/common/utils/constants';
import { getWildcardDomain } from 'views/common/utils/general';
import { getFExists, getFStart } from 'views/services/api-caller';

const logger = getLogger('setup-cookies');

export async function setupCookies() {
  // for cross domain requests to work, we need to set cookie on wild card sub domain
  const hostname = encodeURI(window.location.hostname);
  const wildCardDomain = getWildcardDomain(hostname);
  setCookieWithDomain(DOMAIN_COOKIE, hostname, wildCardDomain);

  if (isLoggedIn()) {
    return getFExists()
      .then((response) => {
        var errorCode = response.status;
        if (response.status === 200) {
          return;
        }
        if (errorCode === 501 || errorCode === 502 || errorCode === 503 || errorCode === 504) {
          console.log(
            '[IN] Server not reachable ' + errorCode + ' we continue as logged in anyway'
          );
        } else if (errorCode === 401 || errorCode == 403) {
          console.log('[IN] Not logged in anymore');
          setLoggedOut();
        } else {
          logger.error('Exception which checking auth status', response.status);
          setLoggedOut();
        }
      })
      .catch((reason: AxiosError) => {
        logger.error('Exception which checking auth status', reason);
        const errorCode = reason.response && reason.response.status;
        if (errorCode === 501 || errorCode === 502 || errorCode === 503 || errorCode === 504) {
          console.log(
            '[IN] Server not reachable ' + errorCode + ' we continue as logged in anyway'
          );
        } else if (errorCode === 401 || errorCode == 403) {
          console.log('[IN] Not logged in anymore');
          setLoggedOut();
        } else {
          logger.error('Exception which checking auth status', errorCode);
          setLoggedOut();
        }
      });
  } else {
    // need to make atleast one get request, so that we can get xsrf cookie set by the server before loading the application
    return getFStart().catch((error) => {
      logger.error(`request failed for /f/start ${error}`);
    });
  }
}
