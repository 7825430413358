export const CompanySortOption = '@fileee/company-sort-option';
export const CompanyOrderOption = '@fileee/company-order-option';

export const DocumentSortOption = '@fileee/document-sort-option';
export const DocumentOrderOption = '@fileee/document-order-option';

export const TagSortOption = '@fileee/tag-sort-option';
export const TagOrderOption = '@fileee/tag-order-option';

export const ConversationSortOption = '@fileee/conversation-sort-option';
export const ConversationOrderOption = '@fileee/conversation-order-option';
export const TeamConversationSortOption = '@fileee/team-conversation-sort-option';
export const TeamConversationOrderOption = '@fileee/team-conversation-order-option';
export const CompanyConversationOrderOption = '@fileee/company-conversation-order-option';

export const SpaceSortOption = '@fileee/space-sort-option';
export const SpaceOrderOption = '@fileee/space-order-option';
export const TeamSpaceSortOption = '@fileee/team-space-sort-option';
export const TeamSpaceOrderOption = '@fileee/team-space-order-option';
export const CompanySpaceOrderOption = '@fileee/company-space-order-option';

export const DocumentLibraryCellViewType = '@fileee/document-library-cell-view-type';
export const SpaceLibraryCellViewType = '@fileee/space-library-cell-view-type';
export const SpaceLibraryTeamCellViewType = '@fileee/space-library-team-cell-view-type';
export const CompanySpaceCellViewType = '@fileee/company-space-cell-view-type';
export const CompanyLibraryCellViewType = '@fileee/company-library-cell-view-type';
export const ConversationDocumentLibraryCellViewType =
  '@fileee/conversation-document-library-cell-view-type';
export const DocumentPickerLibraryCellViewType = '@fileee/document-picker-library-cell-view-type';

export const DocumentCellViewType = '@fileee/document-cell-view-type';
export const DeletedDocumentCellViewType = '@fileee/deleted-document-cell-view-type';

export const ReminderLibraryDueDateFilterType = '@fileee/reminder-library-due-date-filter-type';
export const DocumentRemindersDueDateFilterType = '@fileee/document-reminders-due-date-filter-type';
export const ReminderLibraryAssigneeFilterType = '@fileee/reminder-library-assignee-filter-type';
export const DocumentRemindersAssigneeFilterType =
  '@fileee/document-reminders-assignee-filter-type';
export const ReminderLibraryFilterType = '@fileee/reminder-library-filter-type';

export const FileeeBoxSortOption = '@fileee/fileeebox-sort-option';
export const FileeeBoxOrderOption = '@fileee/fileeebox-order-option';

export const DocumentDefaultPageZoom = '@fileee/document-default-page-zoom';

export const FileeeConversationMessage = '@fileee/conversation-message-';

export const PutDocumentToBoxDoNotShowMessage = '@fileee/put-document-to-box-do-not-show';

export const AddDocumentToBoxDoNotShowMessage = '@fileee/add-document-to-box-do-not-show';
export const MoveDocumentToBoxDoNotShowMessage = '@fileee/move-document-to-box-do-not-show';

export const LeaveMultiEditDoNotShowMessage = '@fileee/leave-multi-edit-do-not-show';

export const DocumentFilterHistory = '@fileee/document-filter-history';

export const DashboardOrderOption = '@fileee/dashboard-order-option';

export const IsLeftNavExpanded = '@fileee/is-left-nav-expanded';

export const ConfirmDocumentDeleteDoNotShowMessage = '@fileee/confirm-document-delete';

export const ShowLeaveConversationDialogOnLogin = '@fileee/show-leave-conversation-dialog-on-login';

export const Show2023BlackWeekBanner = '@fileee/show-2023-black-week-banner';

export const ShowXmasDealBanner = '@fileee/show-xmas-deal-banner';

export const ShowNewYearDealBanner = '@fileee/show-new-year-deal-banner';
