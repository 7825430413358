import React from 'react';
import notificationQueue from './NotificationQueue';
import SnackBar from './SnackBar';

const GlobalSnackBar = () => {
  const [notifications, setNotifications] = React.useState(notificationQueue.notifications || []);

  React.useEffect(() => {
    let dispose = notificationQueue.addListener(() => {
      setNotifications(notificationQueue.notifications);
    });

    return () => {
      dispose();
    };
  }, [setNotifications]);

  const firstNotification = notifications[0];

  function handleClose() {
    notificationQueue.removeNotification(firstNotification);
  }

  if (firstNotification) {
    return <SnackBar notification={firstNotification} onClose={handleClose} />;
  } else {
    return null;
  }
};

export default GlobalSnackBar;
