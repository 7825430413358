import { resetTracking } from 'tracking/mixpanel-tracking';
import { clearCookie } from './utils';
import { clearUtmTrackingCookie } from './utm-utils';

export function setLoggedOut(afterLogoutAction?) {
  try {
    if (BUILD_VAR_IS_EMBEDDED_APP === true) {
      if (__DEV__) {
        console.log('Firing logout event');
      }

      let event = new CustomEvent('fileee-app-user-logout');
      document.dispatchEvent(event);
    }
    clearCookie('userId');
    clearCookie('actingUserId');
    clearCookie('JSESSIONID');
    clearUtmTrackingCookie();
    document.fileeeBasicVar.userId = null;
    document.fileeeBasicVar.actingUserId = null;
    resetTracking();
    //enable tracking after loggout
  } catch (err) {
    console.error(err);
  } finally {
    if (afterLogoutAction) {
      afterLogoutAction();
    }
  }
}
