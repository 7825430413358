export function removeEmptyKeys(originalObj) {
  let filteredObj = Object.keys(originalObj)
    .filter((key) => {
      if (typeof originalObj[key] === 'string') {
        return !!originalObj[key];
      }
      return originalObj[key] != null;
    })
    .reduce((acc, key) => {
      acc[key] = originalObj[key];
      return acc;
    }, {});
  return filteredObj;
}
