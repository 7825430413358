import { setupProfiler } from 'common/profiler';
import { setCookie } from 'common/utils';
import { saveUtmParamsFromUrl } from 'common/utm-utils';
import {
  enableDisableMixpanelBasedOnCookie,
  startAnalytics,
  trackAppOpen
} from 'tracking/mixpanel-tracking';
import { APP_HINT_COOKIE } from 'views/common/utils/constants';
import { isMobileOrTablet } from 'views/common/utils/general';
import { saveSignupParamsFromUrl } from 'views/services/signup-options-state';
import { storeStartUrlInfo } from './start-url-actions';

export function doJsStartActions() {
  try {
    if (BUILD_VAR_APP_TARGET === 'APP_FILEEE') {
      console.log('Starting fileee app .....');
    }

    if (BUILD_VAR_APP_TARGET === 'APP_VGH') {
      console.log('Starting vgh app .....');
    }

    if (BUILD_VAR_APP_TARGET === 'APP_JAMES') {
      console.log('Starting james app .....');
    }

    storeStartUrlInfo();
    disableMobileHintBaseOnRoute();
    saveUtmParamsFromUrl();
    saveSignupParamsFromUrl();

    setupProfiler();
    startAnalytics();
    enableDisableMixpanelBasedOnCookie();
    trackAppOpen();
  } catch (error) {
    console.error(`Exception in js startup ${error}`);
  }
}

function disableMobileHintBaseOnRoute() {
  if (isMobileOrTablet() && shouldDisableMobileHint()) {
    // @ts-ignore
    setCookie(APP_HINT_COOKIE, true);
  }
}

function shouldDisableMobileHint() {
  // const disablePaths = ['/new-password/'];
  const disableParams = [
    '/new-password/',
    'ref=',
    'plan=',
    'business_bundle=',
    'box=',
    'account',
    '/conversations-editor',
    '/external/communication',
    '/actions/'
  ];

  /**
   * @type { string }
   */
  const pathname = window.location.href;

  for (let i = 0; i < disableParams.length; i++) {
    if (pathname.indexOf(disableParams[i]) > -1) {
      return true;
    }
  }
  return false;
}
