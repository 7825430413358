export function removeKeyFromMap<T, V>(sourceMap: Map<T, V>, key: T) {
  if (!sourceMap || sourceMap.size === 0) return sourceMap;
  if (!sourceMap.has(key)) return sourceMap;

  let filteredEntries = Array.from(sourceMap.entries()).filter((entry) => entry[0] !== key);
  return new Map(filteredEntries);
}

export function removeNullValuesFromObject<T>(obj: Record<string, T>): Record<string, T> {
  let newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] != null) {
      newObj[key] = obj[key];
    }
  });

  return newObj;
}
