import i18Next, { InitOptions, i18n, TFunction, TOptions } from 'i18next';
import CustomCallbackBackend from '../scripts/i18next-callback-backend';

// Inspired from https://github.com/aurelia/i18n/blob/master/src/i18n.ts
export class I18N {
  i18next: i18n;
  i18nextDeferred: Promise<i18n>;
  Intl: typeof Intl;
  constructor() {
    this.i18next = i18Next;
    this.i18nextDeferred = null;
    this.Intl = window.Intl;
    // @ts-ignore
    this.i18next.use(CustomCallbackBackend);
  }

  async setup(options: InitOptions) {
    this.i18nextDeferred = new Promise((resolve, reject) => {
      this.i18next.init(options, (err) => {
        if (err && !Array.isArray(err)) {
          reject(err);
        }

        resolve(this.i18next);
      });
    });

    return this.i18nextDeferred;
  }

  async i18nextReady() {
    return this.i18nextDeferred;
  }

  setLocale(locale: string): Promise<TFunction> {
    return new Promise((resolve, reject) => {
      this.i18next.changeLanguage(locale, (err, tr) => {
        if (err) {
          reject(err);
        }
        resolve(tr);
      });
    });
  }

  getLocale() {
    return this.i18next.language;
  }

  nf(options?: Intl.NumberFormatOptions, locales?: string | string[]) {
    return new this.Intl.NumberFormat(locales || this.getLocale(), options || {});
  }

  df(options?: Intl.DateTimeFormatOptions, locales?: string | string[]) {
    return new this.Intl.DateTimeFormat(locales || this.getLocale(), options);
  }

  tr(key: string | string[], options?: TOptions) {
    return this.i18next.t(key, options);
  }
}

export const FileeeI18N = new I18N();
