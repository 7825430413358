//@ts-check
export function hasLocalStorageSupport() {
  var testStorage = 'testStorage';
  try {
    localStorage.setItem(testStorage, testStorage);
    localStorage.removeItem(testStorage);
    return true;
  } catch (e) {
    if (__DEV__) {
      console.log('Local storage not supported', e);
    }
    return false;
  }
}
