import { getSearchParams } from './location-utils';
import { addUpdateCookie, clearFileeeDomainCookie, isTestEnv } from './utils';

interface UtmParams {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

interface AdditionalTrackingParams {
  belboon?: string;
  belboonpartnerid?: string;
}

type TrackingParams = UtmParams & AdditionalTrackingParams;

const FILEEE_UTM_PARAMS_COOKIE = 'fileee-utm-last-seen';

const UTMSourceParam = 'utm_source';
const UTMMediumParam = 'utm_medium';
const UTMCampaignParam = 'utm_campaign';
const UTMTermParam = 'utm_term';
const UTMContentParam = 'utm_content';

export const BelboonParam = 'belboon';

const TEST_ENV_COOKIE_DOMAIN = '.workeee.de';
const PROD_ENV_COOKIE_DOMAIN = '.fileee.com';

export const SupportedFoxyCartTrackingParams = [
  UTMSourceParam,
  UTMMediumParam,
  UTMCampaignParam,
  UTMTermParam,
  UTMContentParam,
  BelboonParam
];

/**
 * https://wiki.foxycart.com/v/2.0/cheat_sheet#transaction_non-product_specific_options
 * using _ke param for belboon to circumvent the need to encrypt parameter
 */
export const BelboonParamForFoxycart = '_ke';

let currentSessionUtmParams: TrackingParams = null;

export function saveUtmParamsFromUrl() {
  let searchParams = getSearchParams();

  let utmParamsFromUrl: TrackingParams = {
    utm_source: searchParams[UTMSourceParam],
    utm_medium: searchParams[UTMMediumParam],
    utm_campaign: searchParams[UTMCampaignParam],
    utm_term: searchParams[UTMTermParam],
    utm_content: searchParams[UTMContentParam],
    belboon: searchParams[BelboonParam],
    belboonpartnerid: searchParams['belboonpartnerid']
  };

  let normalizedUtmParamsFromUrl = {};

  Object.keys(utmParamsFromUrl).forEach((paramName) => {
    if (utmParamsFromUrl[paramName]) {
      normalizedUtmParamsFromUrl[paramName] = utmParamsFromUrl[paramName];
    }
  });

  let currentParamsFromCookie = getUtmParamsFromSessionCookie();

  if (Object.keys(normalizedUtmParamsFromUrl).length > 0) {
    currentSessionUtmParams = normalizedUtmParamsFromUrl;

    let newParamsToBeStoredInCookie = normalizedUtmParamsFromUrl;

    if (currentParamsFromCookie) {
      newParamsToBeStoredInCookie = { ...currentParamsFromCookie, ...normalizedUtmParamsFromUrl };
    }
    addUpdateCookie({
      name: FILEEE_UTM_PARAMS_COOKIE,
      value: JSON.stringify(newParamsToBeStoredInCookie),
      domain: isTestEnv() ? TEST_ENV_COOKIE_DOMAIN : PROD_ENV_COOKIE_DOMAIN,
      maxAge: 30 * 24 * 60 * 60
    });
  } else {
    currentSessionUtmParams = null;
  }
}

function getUtmParamsFromSessionCookie(): TrackingParams {
  let utmParamsFromCookieString = window.getCookieValue(FILEEE_UTM_PARAMS_COOKIE);

  if (!utmParamsFromCookieString) return null;

  try {
    let utmParamsFromCookie = JSON.parse(utmParamsFromCookieString);

    if (Object.keys(utmParamsFromCookie).length === 0) {
      return null;
    }

    let normalizedUtmParamsFromCookie = {};

    Object.keys(utmParamsFromCookie).forEach((paramName) => {
      if (utmParamsFromCookie[paramName]) {
        normalizedUtmParamsFromCookie[paramName] = decodeURIComponent(
          utmParamsFromCookie[paramName]
        );
      }
    });

    return normalizedUtmParamsFromCookie;
  } catch (error) {
    if (__DEV__) {
      console.error(`Failed to parse utm cookie value ${error}`);
    }
  }
  return null;
}

export function setFileeeSpaceUtmParams() {
  if (currentSessionUtmParams) {
    return;
  }
  currentSessionUtmParams = {
    utm_campaign: 'fileee-spaces',
    utm_medium: 'external-conversation'
  };
}

export function setSimpleConversationUtmParams() {
  if (currentSessionUtmParams) {
    return;
  }
  currentSessionUtmParams = {
    utm_medium: 'external-conversation'
  };
}

export function getTrackingParamsToForwardToServer() {
  if (currentSessionUtmParams) {
    return currentSessionUtmParams;
  } else {
    return getUtmParamsFromSessionCookie();
  }
}

export function clearUtmTrackingCookie() {
  clearFileeeDomainCookie(FILEEE_UTM_PARAMS_COOKIE);
}
