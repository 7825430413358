//@ts-check
import { enableDisableMixpanelBasedOnCookie } from 'tracking/mixpanel-tracking';

const STATIC_DOMAIN_WORKEEE_BASE = 'https://static.workeee.de/';
const STATIC_DOMAIN_FILEEEE_BASE = 'https://static.fileee.com/';

/**
 * @export
 * @param {string} userId
 * @param {string} [_actingUserId]
 */
// Remove actingUserId param. We will always rely on session response for it
// eslint-disable-next-line no-unused-vars
export function setLoggedIn(userId, _actingUserId) {
  document.fileeeBasicVar.userId = userId;

  if (window.getCookieValue('userId') !== userId) {
    // If previously logged in user is not  the same as newly logged in user
    // clear local storage so that wrong entites (search history) won't be referenced
    try {
      window.localStorage.clear();
    } catch (error) {}
  }

  setCookie('userId', userId);

  window.dispatchEvent(new CustomEvent('UserLoggedIn', { detail: userId }));
  enableDisableMixpanelBasedOnCookie();
}

/**
 * helper to set cookie
 * @export
 * @param {string} name
 * @param {string} value
 * @returns
 */
export function setCookie(name, value) {
  if (!name) {
    return;
  }
  if (value === null || typeof value === 'undefined') {
    document.cookie = `${name}=;expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
  } else {
    document.cookie = `${name}=${value};expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
  }
}

export function setCookieWithDomain(name, value, domain) {
  if (__DEV__) {
    console.log(` You are in dev environment. 
    So we will not set domain cookie as safari and firefox will break with localhost domain cookie`);
    // document.cookie = `${name}=${value};domain=${domain};expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
  } else {
    document.cookie = `${name}=${value};domain=${domain};expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
  }
}

/**
 *
 * @export
 * @param {string} userId
 */
export function setExternalLogIn(userId, username) {
  document.cookie = 'main_user=' + userId + '; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/';
  window.dispatchEvent(new CustomEvent('UserLoggedIn', { detail: userId }));
  document.fileeeBasicVar.userId = userId;
  document.fileeeBasicVar.actingUserId = null;
  document.fileeeBasicVar.externalUserId = userId;
  document.fileeeBasicVar.externalUsername = username;
}

export function logOutExternalUser() {
  document.cookie = 'main_user=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
  document.fileeeBasicVar.externalUserId = null;
}

export function getTimeLoadingSinceStartup() {
  return window.getTimeLoadingSinceStartup();
}

/**
 * @returns {string}
 */
export function getBaseDomainUrl() {
  return window.getBaseDomainUrl();
}

export function isProductionEnv() {
  return (
    document.fileeeBasicVar.runtimeEnv === 'live' ||
    document.fileeeBasicVar.runtimeEnv === 'release_candidate'
  );
}

export function isLiveEnv() {
  return document.fileeeBasicVar.runtimeEnv === 'live';
}

export function getStaticDomainBase() {
  return isProductionEnv() ? STATIC_DOMAIN_FILEEEE_BASE : STATIC_DOMAIN_WORKEEE_BASE;
}

export function getBaseAPIUrl() {
  return document.fileeeBasicVar.baseAPIUrl ? document.fileeeBasicVar.baseAPIUrl : '';
}

export function isLoggedIn() {
  return window.isLoggedIn();
}

export function gwtScriptAddedToDocument() {
  return document.getElementsByName('gwt_mc_script').length > 0;
}
