import React, { Dispatch } from 'react';
import SnackBar from './SnackBar';
import { randomId } from '../Base';

export type ActionType =
  | { type: 'ADD'; notification: webapp.global.Notification }
  | { type: 'CLOSE' };

const SnackBarContext = React.createContext<Dispatch<ActionType>>(null);

export function useSnackBar() {
  const dispatch = React.useContext(SnackBarContext);

  if (__DEV__) {
    if (!dispatch) {
      console.error(
        `You have to render you components inside SnackBarProvider before using useSnackBar`
      );
    }
  }

  return dispatch;
}

export const NotificationStatus = {
  SUCCESS: 'SUCCESS',
  INFO: 'INFO',
  ERROR: 'ERROR'
};

function reducer(state: webapp.global.Notification[] = [], action: ActionType) {
  switch (action.type) {
    case 'ADD':
      return [{ key: randomId('snackbar'), ...action.notification }];
    case 'CLOSE':
      return [];
    default:
      // @ts-ignore
      throw new Error(`Unhandled action type ${action.type}`);
  }
}

const SnackBarProvider = ({ children, ...rest }) => {
  const [notifications, dispatch] = React.useReducer(reducer, []);

  let firstNotification = notifications[0];

  const handleClose = React.useCallback(() => {
    dispatch({ type: 'CLOSE' });
  }, [dispatch]);

  return (
    <SnackBarContext.Provider value={dispatch} {...rest}>
      {children}
      {firstNotification && (
        <SnackBar
          key={firstNotification.key}
          notification={firstNotification}
          onClose={handleClose}
        />
      )}
    </SnackBarContext.Provider>
  );
};

export default SnackBarProvider;
