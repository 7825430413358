/** @jsx jsx */
import { jsx } from '@emotion/core';
import { MDCSnackbar } from '@material/snackbar/index';
import classNames from 'classnames';
import CheckNoBoundsCircleIcon from 'icons/CheckNoBoundsCircleIcon';
import ClearIcon from 'icons/ClearIcon';
import WarningIcon from 'icons/WarningIcon';
import { getSvgColorCss, onCondition, theme } from 'jsStyles/theme';
import React from 'react';
import Typography from '../Typography';
import { NotificationStatus } from './SnackBarContextNew';

const CssVariables = {
  snackbarBgColor: '--fileee-snackbar-bg-color',
  snackbarDefaultColor: '--fileee-snackbar-default-color',
  snackbarAltColor: '--fileee-snackbar-alt-color',
  snackbarClearIconColor: '--fileee-snackbar-icon-color',
  snackbarButtonHoverOpacity: '--fileee-snackbar-button-hover-opacity'
};

const SnackBar = ({
  notification,
  onClose
}: {
  notification: webapp.global.Notification;
  onClose: () => void;
}) => {
  const containerRef = React.useRef();
  const { isDarkMode = true, actions, variant, status, message, description } = notification;

  React.useEffect(() => {
    function closedListener() {
      onClose();
    }
    const snackbar = new MDCSnackbar(containerRef.current);

    if (notification.timeOutMs) {
      snackbar.timeoutMs = notification.timeOutMs;
    } else if (notification.actions && notification.actions.length > 0) {
      snackbar.timeoutMs = 10000;
    } else {
      snackbar.timeoutMs = Math.min(Math.max(notification.message.length * 100, 4000), 7000);
    }

    snackbar.open();
    snackbar.listen('MDCSnackbar:closed', closedListener);

    return () => {
      snackbar.unlisten('MDCSnackbar:closed', closedListener);
      snackbar.destroy();
    };
  }, [notification, onClose]);

  function handleAction(action: webapp.global.NotificationAction) {
    if (action.onAction) {
      action.onAction();
    }
    onClose();
  }

  const containerClass = classNames('mdc-snackbar', {
    'mdc-snackbar--leading': variant === 'leading',
    'mdc-snackbar--stacked': variant === 'stacked'
  });

  const isErrorMode = status === NotificationStatus.ERROR;
  const textColorClass = isDarkMode || isErrorMode ? 'fTextWhite' : 'fText1';

  return (
    <div
      className={containerClass}
      ref={containerRef}
      css={{
        top: 0,
        bottom: 'auto',
        ['& .mdc-snackbar__surface']: { flexDirection: 'row' },
        ['& .mdc-snackbar__surface .mdc-snackbar__actions']: { alignSelf: 'auto' },
        ['& .mdc-snackbar__surface .mdc-snackbar__label']: { padding: 0 },
        [CssVariables.snackbarBgColor]: theme.colors.grey700,
        [CssVariables.snackbarDefaultColor]: theme.colors.primary,
        [CssVariables.snackbarAltColor]: theme.colors.primary700,
        [CssVariables.snackbarClearIconColor]: '#ffffff',
        [CssVariables.snackbarButtonHoverOpacity]: 1,
        ...onCondition(!isDarkMode, {
          [CssVariables.snackbarBgColor]: '#ffffff',
          [CssVariables.snackbarDefaultColor]: theme.colors.primary700,
          [CssVariables.snackbarAltColor]: theme.colors.primary,
          [CssVariables.snackbarClearIconColor]: theme.colors.grey700
        }),
        ...onCondition(isErrorMode, {
          [CssVariables.snackbarBgColor]: theme.colors.error,
          [CssVariables.snackbarDefaultColor]: '#ffffff',
          [CssVariables.snackbarAltColor]: '#ffffff',
          [CssVariables.snackbarClearIconColor]: '#ffffff',
          [CssVariables.snackbarButtonHoverOpacity]: 0.8
        })
      }}
    >
      <div
        className="mdc-snackbar__surface pa3"
        css={{
          width: '100%',
          maxWidth: 640,
          minHeight: 56,
          borderRadius: 8,
          alignItems: 'flex-start',
          backgroundColor: `var(${CssVariables.snackbarBgColor})`,
          ...onCondition(!isDarkMode, { border: `1px solid ${theme.colors.primary}` })
        }}
      >
        <div css={{ height: 24 }}>
          {isErrorMode ? (
            <WarningIcon css={getSvgColorCss(`var(${CssVariables.snackbarDefaultColor})`)} />
          ) : (
            <CheckNoBoundsCircleIcon
              css={getSvgColorCss(`var(${CssVariables.snackbarDefaultColor})`)}
            />
          )}
        </div>
        <div css={{ flexGrow: 1, wordBreak: 'break-word' }} className={`${textColorClass} pl3`}>
          <Typography
            variant="body2"
            className={`${textColorClass} mdc-snackbar__label u-fMedium`}
            role="status"
            aria-live="polite"
          >
            {message}
          </Typography>
          {description && (
            <Typography variant="body2" className="mt2">
              {description}
            </Typography>
          )}
          {actions && actions.length > 0 && (
            <div className="mt2">
              {actions.map((action, index) => (
                <button
                  key={index}
                  type="button"
                  css={{
                    display: 'inline-flex',
                    backgroundColor: 'transparent',
                    border: 'none',
                    outline: 'none',
                    color: `var(${CssVariables.snackbarDefaultColor})`,
                    ['&:hover , &:focus']: {
                      backgroundColor: 'transparent',
                      color: `var(${CssVariables.snackbarAltColor})`,
                      textDecoration: 'underline',
                      opacity: parseFloat(`var(${CssVariables.snackbarButtonHoverOpacity})`)
                    }
                  }}
                  className={`${index !== 0 && 'ml3'} fi-p-0`}
                  onClick={() => handleAction(action)}
                >
                  {action.label}
                </button>
              ))}
            </div>
          )}
        </div>
        <div className="mdc-snackbar__actions" css={{ height: 24, width: 24 }}>
          <button type="button" className="mdc-icon-button mdc-snackbar__dismiss" onClick={onClose}>
            <ClearIcon css={getSvgColorCss(`var(${CssVariables.snackbarClearIconColor})`)} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SnackBar;
